import { type ApolloError, type MutationFunction, useMutation as useApolloMutation } from '@apollo/client';
import { graphql, type ManufacturingBookingCreateMutation } from '@orthly/graphql-inline-react';

const Mutation = graphql(`
    mutation ManufacturingBookingCreate(
        $bookingAction: String!
        $orderId: String!
        $stage: String
        $stationId: String!
        $substationId: String
    ) {
        manufacturingBookingCreate(
            bookingAction: $bookingAction
            orderId: $orderId
            stage: $stage
            stationId: $stationId
            substationId: $substationId
        ) {
            id
            orderNumber
            stack
        }
    }
`);

export type MutationResult = ManufacturingBookingCreateMutation['manufacturingBookingCreate'];

export type SubmitArgs = {
    bookingAction: string;
    orderId: string;
    stage: string | undefined;
    stationId: string;
    substationId: string | undefined;
};

type ErrorHandler = (args: SubmitArgs, error: ApolloError) => void;
type SuccessHandler = (args: SubmitArgs, result: MutationResult) => void;

export function submit(
    mutation: MutationFunction<ManufacturingBookingCreateMutation, any>,
    onError: ErrorHandler,
    onSuccess: SuccessHandler,
    variables: SubmitArgs,
): void {
    void mutation({ variables })
        .then(res => onSuccess(variables, res.data?.manufacturingBookingCreate))
        .catch(err => onError(variables, err));
}

export function useMutation(onError: ErrorHandler, onSuccess: SuccessHandler) {
    const [mutation, { loading }] = useApolloMutation(Mutation, { fetchPolicy: 'no-cache' });

    const submit_ = (args: SubmitArgs) => submit(mutation, onError, onSuccess, args);

    return { loading, submit: submit_ };
}
