import { useManufacturerIdFromSession } from '../../../../../utils/authorization';
import { orderIdFromQRCodeUrl } from '../../../../../utils/utils';
import {
    DALLAS_STATION_NAME_BY_ID,
    LEHI_STATION_NAME_BY_ID,
    MACHINE_MAP_BY_STATION_ID,
    STAGE_LIST_BY_STATION_ID_DALLAS,
    STAGE_LIST_BY_STATION_ID_LEHI,
} from './BookingForm.const';
import { type MutationResult, type SubmitArgs, useMutation } from './BookingForm.graphql';
import * as BookingForm from './BookingForm.vm';
import { type ScanResult, useViewModel as useScanResultListViewModel } from './components/ScanResultList.vm';
import { type ApolloError } from '@apollo/client';
import { DANDY_TEXAS_MANUFACTURER_ID, LEHI_MANUFACTURER_ID } from '@orthly/shared-types';
import { apolloErrorMessage, type SimpleSelectOption } from '@orthly/ui';
import { type RadioGroupProps } from '@orthly/ui-primitives';
import _ from 'lodash';
import { type EnqueueSnackbar, useSnackbar } from 'notistack';
import React from 'react';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export function getBookingActionOptions(): RadioGroupProps['options'] {
    return [
        { id: 'ON', label: 'BOOK ON' },
        { id: 'OFF', label: 'BOOK OFF' },
    ];
}

export function getStageOptions(mfgId: string | undefined, stationId: string | undefined): SimpleSelectOption[] {
    if (!mfgId || !stationId) {
        return [];
    }
    let stageListByStationId: Record<string, string[]> | undefined = undefined;
    if (mfgId === DANDY_TEXAS_MANUFACTURER_ID) {
        stageListByStationId = STAGE_LIST_BY_STATION_ID_DALLAS;
    }
    if (mfgId === LEHI_MANUFACTURER_ID) {
        stageListByStationId = STAGE_LIST_BY_STATION_ID_LEHI;
    }
    if (!stageListByStationId) {
        return [];
    }
    const stages = stageListByStationId[stationId];
    if (!stages) {
        return [];
    }
    const opts = stages.map(stage => ({ label: stage, value: stage }));
    return opts.sort((a, b) => a.label.localeCompare(b.label));
}

export function getStationIdOptions(mfgId: string | undefined): SimpleSelectOption[] {
    let nameById: Record<string, string> | undefined = undefined;
    if (mfgId === DANDY_TEXAS_MANUFACTURER_ID) {
        nameById = DALLAS_STATION_NAME_BY_ID;
    }
    if (mfgId === LEHI_MANUFACTURER_ID) {
        nameById = LEHI_STATION_NAME_BY_ID;
    }
    if (!nameById) {
        return [];
    }
    const entries = Object.entries(nameById);
    const opts = entries.map(([id, name]) => ({ label: name, value: id }));
    return opts.sort((a, b) => a.label.localeCompare(b.label));
}

export function getSubstationIdOptions(stationId: string | undefined): SimpleSelectOption[] {
    if (!stationId) {
        return [];
    }
    const nameById: Record<string, string> | undefined = MACHINE_MAP_BY_STATION_ID[stationId];
    if (!nameById) {
        return [];
    }
    const entries = Object.entries(nameById);
    const opts = entries.map(([id, name]) => ({ label: name, value: id }));
    return opts.sort((a, b) => a.label.localeCompare(b.label));
}

export function isDisabled(
    bookingAction: string,
    loading: boolean,
    mfgId: string | undefined,
    stationId: string,
    substationId: string | undefined,
): boolean {
    if (loading) {
        return true;
    }
    if (bookingAction.trim() === '' || stationId.trim() === '') {
        return true;
    }
    const stationIds = BookingForm.getStationIdOptions(mfgId).map(i => i.value);
    if (stationIds.includes(stationId) === false) {
        return true;
    }
    if (substationId) {
        const opts = BookingForm.getSubstationIdOptions(stationId);
        const values = opts.map(i => i.value);
        if (values.includes(substationId) === false) {
            return true;
        }
    }
    return false;
}

export function onError(
    addScanResult: (v: ScanResult) => void,
    enqueueSnackbar: EnqueueSnackbar,
    args: SubmitArgs,
    error: ApolloError,
): void {
    addScanResult({ id: args.orderId, result: 'ERROR' });
    const message = apolloErrorMessage(error);
    enqueueSnackbar(message, { variant: 'error' });
}

export function onSuccess(
    addScanResult: (v: ScanResult) => void,
    enqueueSnackbar: EnqueueSnackbar,
    args: SubmitArgs,
    result: MutationResult,
) {
    if (result) {
        addScanResult({
            id: args.orderId,
            orderNumber: result.orderNumber ?? '',
            result: 'SUCCESS',
            stack: result.stack ?? '',
        });
    }
    enqueueSnackbar('Booking complete.', { variant: 'success' });
}

export type ReduceScanResultsAction = { value: ScanResult };

export function reduceScanResults(scanResults: ScanResult[], action: ReduceScanResultsAction): ScanResult[] {
    const combined = scanResults.concat(action.value);
    return _.uniqBy(combined, 'orderNumber');
}

export function setBookingAction(setState: SetState<string>, value: string): void {
    setState(value.trim());
}

export function setStage(setState: SetState<string | undefined>, value: string | undefined): void {
    const v = value ? value.trim() : undefined;
    setState(v);
}

export function setStationId(
    setStationId: SetState<string>,
    setSubstationId: SetState<string | undefined>,
    value: string | undefined,
): void {
    const v = value ?? '';
    setStationId(v.trim());
    setSubstationId(undefined);
}

export function setSubstationId(setState: SetState<string | undefined>, value: string | undefined): void {
    const v = value ? value.trim() : undefined;
    setState(v);
}

export function submit(
    args: {
        bookingAction: string;
        orderUrl: string;
        stage: string | undefined;
        stationId: string;
        substationId: string | undefined;
    },
    hooks: {
        enqueueSnackbar: EnqueueSnackbar;
        mutation: (args: SubmitArgs) => void;
    },
    loading: boolean,
): void {
    const { bookingAction, orderUrl, stage, stationId, substationId } = args;
    const { enqueueSnackbar, mutation } = hooks;

    if (loading) {
        enqueueSnackbar('Booking already in progress.');
        return;
    }

    const orderId = orderIdFromQRCodeUrl(orderUrl);

    if (!orderId) {
        enqueueSnackbar('Please scan a valid QR code.', { variant: 'error' });
        return;
    }

    mutation({
        bookingAction,
        orderId,
        stage,
        stationId,
        substationId,
    });
}

export function useViewModel() {
    const { enqueueSnackbar } = useSnackbar();
    const mfgId = useManufacturerIdFromSession();

    const { addScanResult, clearScanResults, scanResults } = useScanResultListViewModel();

    const onError_ = (a: SubmitArgs, e: ApolloError) => onError(addScanResult, enqueueSnackbar, a, e);
    const onSuccess_ = (a: SubmitArgs, r: MutationResult) => onSuccess(addScanResult, enqueueSnackbar, a, r);
    const { loading, submit: submitMutation } = useMutation(onError_, onSuccess_);

    const [bookingAction, setBookingActionHook] = React.useState<string>('');
    const [stationId, setStationIdHook] = React.useState<string>('');
    const [substationId, setSubstationIdHook] = React.useState<string | undefined>(undefined);
    const [stage, setStageHook] = React.useState<string | undefined>(undefined);

    const bookingActionOptions = getBookingActionOptions();
    const disabled = isDisabled(bookingAction, loading, mfgId, stationId, substationId);
    const stageOptions = getStageOptions(mfgId, stationId);
    const stationIdOptions = getStationIdOptions(mfgId);
    const substationIdOptions = getSubstationIdOptions(stationId);

    const setBookingAction_ = (v: string) => setBookingAction(setBookingActionHook, v);
    const submit_ = (v: string) =>
        submit(
            { bookingAction, orderUrl: v, stage, stationId, substationId },
            { enqueueSnackbar, mutation: submitMutation },
            loading,
        );
    const setStage_ = (v?: string) => setStage(setStageHook, v);
    const setStationId_ = (v?: string) => setStationId(setStationIdHook, setSubstationIdHook, v);
    const setSubstationId_ = (v?: string) => setSubstationId(setSubstationIdHook, v);

    return {
        bookingAction,
        bookingActionOptions,
        clearScanResults,
        disabled,
        loading,
        setBookingAction: setBookingAction_,
        setStage: setStage_,
        setStationId: setStationId_,
        setSubstationId: setSubstationId_,
        scanResults,
        stage,
        stageOptions,
        stationId,
        stationIdOptions,
        submit: submit_,
        substationId,
        substationIdOptions,
    };
}
