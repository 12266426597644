import { type ScanResult } from './ScanResultList.vm';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

type Props = {
    scanResults: ScanResult[];
};

/** Display a list of scan results. */
export const ScanResultList: React.VFC<Props> = ({ scanResults }) => {
    return (
        <Grid container>
            <Grid item xs={4} />
            <Grid item xs={8}>
                {scanResults.length} Scanned Cases
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={8}>
                {scanResults.map(i => (
                    <Grid item key={i.id}>
                        {i.result === 'ERROR' && (
                            <a href={`/lab/${i.id}`} target={'_blank'}>
                                {i.result} - {i.id}
                            </a>
                        )}
                        {i.result === 'SUCCESS' && (
                            <span>
                                {i.result} - {i.stack} - {i.orderNumber}
                            </span>
                        )}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};
