/* eslint-disable max-lines */

/** Dallas station names keyed by id value. */
export const DALLAS_STATION_NAME_BY_ID: Record<string, string> = {
    '3DPRT-MACH': '3D Printing - Machines',
    '3DPRT-MPRI': 'AAA - 3D Printing - Model Printing Technicians',
    '3DPRT-NEST': '3D Printing - Nesting Technicians',
    '3DPRT-PRNT': '3D Printing - Printing Technicians',
    'CROWN-NEST': 'AAA - Crown Nesting',
    'DENT-FIN': 'AAA - Dentures Finishing',
    'DENT-FIT': 'Dentures Model Fit',
    'DENT-INJEC': 'Dentures Injection',
    'NGT-GUARD': 'AAA - Night Guard Finishing',
    QC: 'AAA - QC',
    'SG-FC': 'AAA - Stain & Glaze: Full Contour',
    SINGULATE: 'Singulation - Technicians',
    'SINT-MACH': 'Sintering - Machines',
    'SINT-TECH': 'Sintering - Technicians',
    'TEETH-FIT': 'Teeth Fitment',
    'THERMO-NG': 'Thermoform Nightguards',
    'ZIRC-MILL': 'AAA - Zirconia Milling',
};

/** Lehi station names keyed by id value. */
export const LEHI_STATION_NAME_BY_ID: Record<string, string> = {
    '3DPRT-MACH': '3D Printing - Machines',
    '3DPRT-MPRI': 'AAA - 3D Printing - Model Printing Technicians',
    '3DPRT-NEST': '3D Printing - Nesting Technicians',
    '3DPRT-PRNT': '3D Printing - Printing Technicians',
    'ABUT-MILL': 'AAA - Abutment Mills - AXIS',
    'CROWN-NEST': 'AAA - Crown Nesting',
    'DENT-FIN': 'AAA - Dentures Finishing',
    'DESGN-CB': 'Design - Crown & Bridge',
    'EMAX-MILL': 'AAA - Emax Mills',
    'IMP-DEPT': 'AAA - Implants Department',
    'IMP-FINISH': 'Implants - Finishing Technicians',
    'MILL-MACH': 'Milling - Machines',
    'MILL-NEST': 'Milling - Nesting Technicians',
    'NGT-GUARD': 'AAA - Night Guard Finishing',
    ORDERENTRY: 'Order Entry',
    ORDERMGMT: 'Order Mgmt',
    'PARTS-PULL': 'AAA - Inventory Parts Pull',
    QC: 'AAA - QC',
    'QC - 4': 'QC - Four (Modeless, Emax, Denture)',
    'QC - 5': 'QC - Five (Model)',
    'QC - 6': 'QC - Six (PFZ, Implant)',
    'QC IMP-ANT': 'QC - Implants & Anterior',
    'QC-2': 'QC - Two (SG, NG, Try-in)',
    'QC-DENTURE': 'QC- Denture',
    'QC-POSTERI': 'QC - Posterior',
    'QC-REMOVAB': 'QC - Removables',
    'REMOV-MACH': 'Removables - Machines',
    'REMOV-TECH': 'Removables - Finishing Technicians',
    'SG-ANTLY': 'Stain & Glaze - Anterior Layered',
    'SG-ANTNOLY': 'ANTERIOR FULL CONTOUR',
    'SG-FC': 'AAA - Stain & Glaze: Full Contour',
    'SG-LAYERED': 'AAA - Stain & Glaze Layered',
    'SG-POSLY': 'Stain & Glaze Posterior Layered',
    'SG-POSNOLY': 'Stain & Glaze - Posterior Full Contour',
    SINGULATE: 'Singulation - Technicians',
    'SINT-EMAX': 'AAA - Emax Sintering',
    'SINT-MACH': 'Sintering - Machines',
    'SINT-TECH': 'Sintering - Technicians',
    'STRAU-MILL': 'AAA - Straumann Mills',
    'SURG-FIN': 'AAA - Surgical Guide Finishing',
    'ZIRC-MILL': 'AAA - Zirconia Milling',
};

/** 3D printer machine names keyed by id. */
export const _3DPRT_PRNT: Record<string, string> = {
    'PRNT M2-01': 'Machine - Printer M2660R',
    'PRNT M2-02': 'Machine - Printer M2618R',
    'PRNT M2-03': 'Machine - Printer M2693R',
    'PRNT M3-02': 'Machine - Printer m3070',
    'PRNT M3-03': 'Machine - Printer M3073',
    'PRNT M3-04': 'Machine - Printer M3069',
    'PRNT M3-08': 'Machine - Printer M3121',
    'PRNT M3-10': 'Machine - Printer M3075',
    'PRNT M3-11': 'Machine - Printer MX3048',
    'PRNT M3-13': 'Machine - Printer M3067',
    'PRNT MX-01': 'Machine - Printer MX 3027',
};

/** Abutment mill machine names keyed by id. */
export const ABUT_MILL_MACHINE_NAME_BY_ID: Record<string, string> = {
    'MLL 200-01': 'Machine - Mill 5X-200 nb 1',
    'MLL 200-02': 'Machine - Mill 5X-200 nb 2',
    'MLL 200-03': 'Machine - Mill 5X-200 nb 3',
    'MLL 200-04': 'Machine - Mill 5X-200 nb 4',
};

/** Sintering EMAX machine names keyed by id. */
export const SINT_EMAX: Record<string, string> = {
    'SNT DKEM01': 'Machine - Sintering Oven Dekema Emax 01',
    'SNT DKEM02': 'Machine - Sintering Oven Dekema Emax 02',
};

/** Sintering machine names keyed by id. */
export const SINT_MACH: Record<string, string> = {
    'SINT DK-01': 'Machine - Sintering Oven Dekema 674i nb 1',
    'SINT DK-02': 'Machine - Sintering Oven Dekema 674i nb 2',
    'SINT DK-03': 'Machine - Sintering Oven Dekema 674i nb 3',
    'SINT DK-04': 'Machine - Sintering Oven Dekema 674i nb 4',
    'SINT DK-05': 'Machine - Sintering Oven Dekema 674i nb 5',
    'SINT DK-07': 'Machine - Sintering Oven Dekema 674i nb 7',
    'SINT DK-08': 'Machine - Sintering Oven Dekema 674i nb 8',
    'SINT DK-09': 'Machine - Sintering Oven Dekema 674i nb 9',
    'SINT DK-10': 'Machine - Sintering Oven Dekema 674i nb 10',
    'SINT DK-11': 'Machine - Sintering Oven Dekema 674i nb 11',
    'SINT DK-12': 'Machine - Sintering Oven Dekema 674i nb 12',
    'SINT DK-13': 'Machine - Sintering Oven Dekema 674i nb 13',
    'SINT DK-14': 'Machine - Sintering Oven Dekema 674i nb 14',
    'SINT DK-15': 'Machine - Sintering Oven Dekema 674i nb 15',
    'SINT DK-16': 'Machine - Sintering Oven Dekema 674i nb 16',
    'SINT DK-17': 'Machine - Sintering Oven Dekema 674i nb 17',
    'SINT DK-18': 'Machine - Sintering Oven Dekema 674i nb 18',
    'SINT DK-19': 'Machine - Sintering Oven Dekema 674i nb 19',
    'SINT DK-20': 'Machine - Sintering Oven Dekema 674i nb 20',
    'SINT DK-21': 'Machine - Sintering Oven Dekema 674i nb 21',
    'SNT DK-01L': 'Machine - Sintering Oven Dekema 674i nb 1L',
    'SNT DK-02L': 'Machine - Sintering Oven Dekema 674i nb 2L',
    'SNT DK-03L': 'Machine - Sintering Oven Dekema 674i nb 3L',
    'SNT DK-04L': 'Machine - Sintering Oven Dekema 674i nb 4L',
    'SNT DK-05L': 'Machine - Sintering Oven Dekema 674i nb 5L',
    'SNT DK-06L': 'Machine - Sintering Oven Dekema 674i nb 6L',
    'SNT DK-07L': 'Machine - Sintering Oven Dekema 674i nb 7L',
    'SNT DK-08L': 'Machine - Sintering Oven Dekema 674i nb 8L',
    'SNT DK-09L': 'Machine - Sintering Oven Dekema 674i nb 9L',
    'SNT DK-10L': 'Machine - Sintering Oven Dekema 674i nb 10L',
    'SNT DK-11L': 'Machine - Sintering Oven Dekema 674i nb 11L',
    'SNT DK-12L': 'Machine - Sintering Oven Dekema 674i nb 12L',
    'SNT DK-13L': 'Machine - Sintering Oven Dekema 674i nb 13L',
    'SNT DK-14L': 'Machine - Sintering Oven Dekema 674i nb 14L',
    'SNT DK-15L': 'Machine - Sintering Oven Dekema 674i nb 15L',
    'SNT DK-16L': 'Machine - Sintering Oven Dekema 674i nb 16L',
    'SNT DK-17L': 'Machine - Sintering Oven Dekema 674i nb 17L',
    'SNT DK-18L': 'Machine - Sintering Oven Dekema 674i nb 18L',
    'SNT DK-19L': 'Machine - Sintering Oven Dekema 674i nb 19L',
    'SNT DK-20L': 'Machine - Sintering Oven Dekema 674i nb 20L',
    'SNT DK-21L': 'Machine - Sintering Oven Dekema 674i nb 21L',
};

/** Straumann mill machine names keyed by id. */
export const STRAU_MILL_MACHINE_NAME_BY_ID: Record<string, string> = {
    'STR MLL01': 'Machine - Straumann Abutment Mill 01',
    'STR MLL02': 'Machine - Straumann Abutment Mill 02',
};

/** Zirconia mill machine names keyed by id. */
export const ZIRC_MILL_MACHINE_NAME_BY_ID: Record<string, string> = {
    'MLL 500-01': 'Machine - Mill 5X-500 nb 01',
    'MLL 500-02': 'Machine - Mill 5X-500 nb 02',
    'MLL 500-03': 'Machine - Mill 5X-500 nb 03',
    // 'MLL 500-03': 'Mill 5X-500 03',
    'MLL 500-04': 'Machine - Mill 5X-500 nb 04',
    // 'MLL 500-04': 'Machine - Mill 5X-500L nb 4',
    'MLL 500-05': 'Machine - Mill 5X-500 nb 05',
    'MLL 500-06': 'Machine - Mill 5X-500 nb 06',
    'MLL 500-07': 'Machine - Mill 5X-500 nb 07',
    'MLL 500-08': 'Machine - Mill 5X-500 nb 08',
    'MLL 500-09': 'Machine - Mill 5X-500 nb 09',
    // 'MLL 500-09': 'Mill 5X-500 09',
    'MLL 500-10': 'Machine - Mill 5X-500 nb 10',
    'MLL 500-11': 'Machine - Mill 5X-500 nb 11',
    // 'MLL 500-11': 'Mill 5X-500 11',
    'MLL 500-12': 'Machine - Mill 5X-500 nb 12',
    // 'MLL 500-12': 'Mill 5X-500 12',
    'MLL 500-13': 'Machine - Mill 5X-500 nb 13',
    // 'MLL 500-13': 'Mill 5X-500 13',
    'MLL 500-14': 'Machine - Mill 5X-500 nb 14',
    'MLL 500-15': 'Machine - Mill 5X-500 nb 15',
    'MLL 500-16': 'Machine - Mill 5X-500 nb 16',
    'MLL 500-17': 'Machine - Mill 5X-500 nb 17',
    'MLL 500-18': 'Machine - Mill 5X-500 nb 18',
    'MLL 500-19': 'Machine - Mill 5X-500 nb 19',
    'MLL 500-20': 'Machine - Mill 5X-500 nb 20',
    'MLL 500-21': 'Machine - Mill 5X-500 nb 21',
    'MLL 500-22': 'Machine - Mill 5X-500 nb 22',
    'MLL 500-23': 'Machine - Mill 5X-500 nb 23',
    'MLL 500-24': 'Machine - Mill 5X-500 nb 24',
    'MLL 500-25': 'Machine - Mill 5X-500 nb 25',
    'MLL 500-26': 'Machine - Mill 5X-500 nb 26',
    'MLL 500-27': 'Machine - Mill 5X-500 nb 27',
    'MLL 500-28': 'Machine - Mill 5X-500 nb 28',
    'MLL 500-29': 'Machine - Mill 5X-500 nb 29',
    'MLL 500-30': 'Machine - Mill 5X-500 nb 30',
    'MLL 500-31': 'Machine - Mill 5X-500 nb 31',
    'MLL 500-32': 'Machine - Mill 5X-500 nb 32',
    'MLL 500-33': 'Machine - Mill 5X-500 nb 33',
    'MLL 500-34': 'Machine - Mill 5X-500 nb 34',
    'MLL 500-35': 'Machine - Mill 5X-500 nb 35',
    'MLL 500-36': 'Machine - Mill 5X-500 nb 36',
    'MLL 500L01': 'Machine - Mill 5X-500L nb 1',
    'MLL 500L02': 'Machine - Mill 5X-500L nb 2',
    'MLL 700-01': 'Machine - Mill 7x700 nb1',
};

/** Machine id-name maps keyed by station id. */
export const MACHINE_MAP_BY_STATION_ID: Record<string, Record<string, string>> = {
    '3DPRT-PRNT': _3DPRT_PRNT,
    'ABUT-MILL': ABUT_MILL_MACHINE_NAME_BY_ID,
    'SINT-EMAX': SINT_EMAX,
    'SINT-MACH': SINT_MACH,
    'STRAU-MILL': STRAU_MILL_MACHINE_NAME_BY_ID,
    'ZIRC-MILL': ZIRC_MILL_MACHINE_NAME_BY_ID,
};

/** TODO: [2024-11-01] Get rid of this. */
export const REMOVE: Record<string, string> = {
    CHEWYSTRAU: 'Machine - Chewy-Straumann Mill',
    DAYTE1: '(Team) Mickey Mouse',
    DAYTE2: '(Team) Power Puff Girls',
    L: 'Machine - Sintering Oven Dekema 674i nb 8L',
    LABTRAC: 'Labtrac Test',
    // MICYOU: '',
    MICYOU: 'michael.young@meetdandy.co',
    'MLL EMX-00': 'Machine - Mill Emax 00',
    'MLL EMX-01': 'Machine - Mill Emax 01',
    'MLL EMX-02': 'Machine - Mill Emax 02',
    'MLL EMX-03': 'Machine - Mill Emax 03',
    'MLL EMX-04': 'Machine - Mill Emax 04',
    NIGTE1: '(Team) Nightengale',
    NIGTE3: '(Team) Night Team 3',
    NIGTE4: '(Team) Night Team 4',
    NOTECH: 'No Tech',
    PARTTEMP: 'Parts Pull Temp Employee',
    'SNT DKOOAL': 'Machine - Sintering Oven Dekema 674i 00AL',
    'SNT DKOOAU': 'Machine - Sintering Oven Dekema 674i 00AU',
    'SNT DKOOBL': 'Machine - Sintering Oven Dekema 674i 00BL',
    'SNT DKOOBU': 'Machine - Sintering Oven Dekema 674i 00BU',
    'SNT DKOOCL': 'Machine - Sintering Oven Dekema 674i 00CL',
    'SNT DKOOCU': 'Machine - Sintering Oven Dekema 674i 00Cu',
    // 'SNT DKOOCU': 'Machine - Sintering Oven Dekema 674i nb 00CU',
    'SNT DKOODL': 'Machine - Sintering Oven Dekema 674i 00DL',
    'SNT DKOODU': 'Machine - Sintering Oven Dekema 674i 00Du',
    'SNT DKOOEL': 'Machine - Sintering Oven Dekema 674i 00EL',
    'SNT DKOOEU': 'Machine - Sintering Oven Dekema 674i 00Eu',
    'SNT DKOOFL': 'Machine - Sintering Oven Dekema 674i 00FL',
    'SNT DKOOFU': 'Machine - Sintering Oven Dekema 674i 00FU',
    'SNT DKOOGL': 'Machine - Sintering Oven Dekema 674i 00GL',
    'SNT DKOOGU': 'Machine - Sintering Oven Dekema 674i nb 00GU',
    'SNT DKOOHL': 'Machine - Sintering Oven Dekema 674i nb 00HL',
    'SNT DKOOHU': 'Machine - Sintering Oven Dekema 674i nb 00HU',
};

export const STAGE__3DPRT_MACH__DALLAS: string[] = [
    'Model Print',
    'Print Night Guard',
    'Print Partial Denture',
    'Print Wax Rim',
    '[A] Model Print',
    '[A] Print Night Guard',
    '[A] Print Partial Denture',
    '[A] Print Wax Rim',
    '[R] Model Print',
    '[R] Print Night Guard',
    '[R] Print Partial Denture',
    '[R] Print Wax Rim',
    '[R] [R] Print Partial Denture',
    '[R] [R] Print Wax Rim',
];

export const STAGE__3DPRT_MPRI__DALLAS: string[] = [
    'Articulate Model',
    'Post Process Model/Die',
    '[A] Post Process Model/Die',
    '[R] Articulate Model',
    '[R] Post Process Model/Die',
];

export const STAGE__3DPRT_NEST__DALLAS: string[] = [
    'Model Nesting',
    'Nest Night Guard',
    'Nest Partial Denture',
    'Nest T-Die',
    'Nest Wax Rim',
    '[A] Model Nesting',
    '[A] Nest Night Guard',
    '[A] Nest Partial Denture',
    '[A] Nest Wax Rim',
    '[A] [A] Nest Partial Denture',
    '[R] Model Nesting',
    '[R] Nest Night Guard',
    '[R] Nest Partial Denture',
    '[R] Nest Wax Rim',
];

export const STAGE__3DPRT_PRNT__DALLAS: string[] = [
    'Post Process Night Guard',
    'Post Process Partial Denture',
    'Post Process Wax Rim',
    '[A] Post Process Night Guard',
    '[A] Post Process Partial Denture',
    '[A] Post Process Wax Rim',
    '[R] Post Process Night Guard',
    '[R] Post Process Partial Denture',
    '[R] Post Process Wax Rim',
    '[R] [R] Post Process Partial Denture',
];

export const STAGE__CROWN_NEST__DALLAS: string[] = [
    'Nest PMMA',
    'Zirconia Nesting',
    '[A] Nest PMMA',
    '[A] Zirconia Nesting',
    '[R] Nest PMMA',
    '[R] Zirconia Nesting',
    '[R] [R] Nest PMMA',
];

export const STAGE__DENT_FIN__DALLAS: string[] = [
    'Partial Denture Finishing 2',
    'Partial Denture Polish',
    'Wax Rim Finishing 1',
    'Wax Rim Finishing 2',
    '[A] Partial Denture Polish',
    '[R] Partial Denture Polish',
    '[R] Wax Rim Finishing 1',
    '[R] Wax Rim Finishing 2',
];

export const STAGE__DENT_FIT__DALLAS: string[] = [
    'Partial Denture Finishing 1',
    'Partial Denture Model Fit',
    '[A] Partial Denture Model Fit',
    '[R] Partial Denture Model Fit',
    '[R] [R] Partial Denture Model Fit',
];

export const STAGE__DENT_INJEC__DALLAS: string[] = [
    'Partial Denture Finishing 1',
    'Partial Denture Injection',
    '[A] Partial Denture Injection',
    '[A] [A] Partial Denture Injection',
    '[R] Partial Denture Injection',
    '[R] [R] Partial Denture Injection',
];

export const STAGE__NGT_GUARD__DALLAS: string[] = ['Polish & Finish NG', '[R] Polish & Finish NG'];

export const STAGE__QC__DALLAS: string[] = [
    'QC - Posterior FC',
    'QC Anterior FC',
    'QC NG',
    'QC Partial Denture',
    '[A] QC - Posterior FC',
    '[A] QC Anterior FC',
    '[R] QC - Posterior FC',
    '[R] QC Anterior FC',
    '[R] QC NG',
];

export const STAGE__SG_FC__DALLAS: string[] = [
    'Adjust Contacts',
    'Glaze Crown',
    'Inspect Crown',
    'SG - Anterior Zirc',
    'SG - Posterior Model',
    'SG - Posterior Zirc',
    'SG Anterior FC',
    'Stain Crown',
    '[A] Adjust Contacts',
    '[A] Glaze Crown',
    '[A] Inspect Crown',
    '[A] SG - Anterior Zirc',
    '[A] SG - Posterior Zirc',
    '[A] Stain Crown',
    '[A] [A] Glaze Crown',
    '[R] Adjust Contacts',
    '[R] Glaze Crown',
    '[R] Inspect Crown',
    '[R] SG - Anterior Zirc',
    '[R] SG - Posterior Zirc',
    '[R] Stain Crown',
];

export const STAGE__SINGULATE__DALLAS: string[] = [
    'Singulate Crown & Bridge',
    '[A] Singulate Crown & Bridge',
    '[R] Singulate Crown & Bridge',
    '[R] [R] Singulate Crown & Bridge',
];

export const STAGE__SINT_MACH__DALLAS: string[] = [
    'Sinter Crown & Bridge',
    '[A] Sinter Crown & Bridge',
    '[R] Sinter Crown & Bridge',
];

export const STAGE__SINT_TECH__DALLAS: string[] = [
    'Sorting - C&B',
    '[A] Sorting - C&B',
    '[R] Sorting - C&B',
    '[R] [R] Sorting - C&B',
];

export const STAGE__TEETH_FIT__DALLAS: string[] = [
    'Teeth Fitment',
    '[A] Teeth Fitment',
    '[A] [A] Teeth Fitment',
    '[R] Teeth Fitment',
    '[R] [R] Teeth Fitment',
];

export const STAGE__THERMO_NG__DALLAS: string[] = [
    'Form & Finish Thermoform NG',
    '[A] Form & Finish Thermoform NG',
    '[R] Form & Finish Thermoform NG',
];

export const STAGE__ZIRC_MILL__DALLAS: string[] = [
    'Mill PMMA',
    'Zirconia Milling',
    '[A] Mill PMMA',
    '[A] Zirconia Milling',
    '[R] Mill PMMA',
    '[R] Zirconia Milling',
    '[R] [R] Mill PMMA',
];

export const STAGE_LIST_BY_STATION_ID_DALLAS: Record<string, string[]> = {
    '3DPRT-MACH': STAGE__3DPRT_MACH__DALLAS,
    '3DPRT-MPRI': STAGE__3DPRT_MPRI__DALLAS,
    '3DPRT-NEST': STAGE__3DPRT_NEST__DALLAS,
    '3DPRT-PRNT': STAGE__3DPRT_PRNT__DALLAS,
    'CROWN-NEST': STAGE__CROWN_NEST__DALLAS,
    'DENT-FIN': STAGE__DENT_FIN__DALLAS,
    'DENT-FIT': STAGE__DENT_FIT__DALLAS,
    'DENT-INJEC': STAGE__DENT_INJEC__DALLAS,
    'NGT-GUARD': STAGE__NGT_GUARD__DALLAS,
    QC: STAGE__QC__DALLAS,
    'SG-FC': STAGE__SG_FC__DALLAS,
    SINGULATE: STAGE__SINGULATE__DALLAS,
    'SINT-MACH': STAGE__SINT_MACH__DALLAS,
    'SINT-TECH': STAGE__SINT_TECH__DALLAS,
    'TEETH-FIT': STAGE__TEETH_FIT__DALLAS,
    'THERMO-NG': STAGE__THERMO_NG__DALLAS,
    'ZIRC-MILL': STAGE__ZIRC_MILL__DALLAS,
};

export const STAGE__3DPRT_MACH__LEHI: string[] = [
    'AAA - Model Print',
    'AAA - Print Abutment Insertion Guide',
    'AAA - Print Denture Base',
    'AAA - Print Denture Teeth',
    'AAA - Print Night Guard',
    'AAA - Print Printed Temp',
    'AAA - Print Soft Tissue',
    'AAA - Print Surgical Guide',
    'AAA - Print Try In',
    'Cure, Sort and Articulate Model',
    'Print 3D Model',
    'Print Denture Base',
    'Print Denture Teeth',
    'Print Night Guard',
    'Print Surgical Guide',
    '[A] AAA - Model Print',
    '[A] AAA - Print Abutment Insertion Guide',
    '[A] AAA - Print Denture Base',
    '[A] AAA - Print Denture Teeth',
    '[A] AAA - Print Night Guard',
    '[A] AAA - Print Printed Temp',
    '[A] AAA - Print Soft Tissue',
    '[A] AAA - Print Surgical Guide',
    '[A] AAA - Print Try In',
    '[A] Cure, Sort and Articulate Model',
    '[A] Print Denture Teeth',
    '[A] [A] AAA - Model Print',
    '[R] AAA - Model Print',
    '[R] AAA - Print Abutment Insertion Guide',
    '[R] AAA - Print Denture Base',
    '[R] AAA - Print Denture Teeth',
    '[R] AAA - Print Night Guard',
    '[R] AAA - Print Printed Temp',
    '[R] AAA - Print Soft Tissue',
    '[R] AAA - Print Surgical Guide',
    '[R] AAA - Print Try In',
    '[R] Cure, Sort and Articulate Model',
    '[R] Print Denture Teeth',
    '[R] Print Night Guard',
    '[R] [R] AAA - Model Print',
];

export const STAGE__3DPRT_MPRI__LEHI: string[] = [
    'AAA - Articulate Model',
    'AAA - Match & Articulate',
    'AAA - Model Match & Articulate',
    'AAA - Model Wash & Cure',
    'AAA - Post Process Model/Die',
    '[A] AAA - Articulate Model',
    '[A] AAA - Match & Articulate',
    '[A] AAA - Model Match & Articulate',
    '[A] AAA - Model Wash & Cure',
    '[A] AAA - Post Process Model/Die',
    '[R] AAA - Articulate Model',
    '[R] AAA - Model Match & Articulate',
    '[R] AAA - Model Wash & Cure',
    '[R] AAA - Post Process Model/Die',
];

export const STAGE__3DPRT_NEST__LEHI: string[] = [
    '3D Print - Nest Model',
    'AAA - Model Nesting',
    'AAA - Nest Abutment Insertion Guide',
    'AAA - Nest Denture Base',
    'AAA - Nest Denture Teeth',
    'AAA - Nest Night Guard',
    'AAA - Nest Printed Temp Crown',
    'AAA - Nest Soft Tissue',
    'AAA - Nest Surgical Guide',
    'AAA - Nest T-Die',
    'AAA - Nest Try-In',
    'Nest Abutment Insertion Guide',
    'Nest Denture Teeth + Base',
    'Nest Model',
    'Nest Night Guard',
    'STL Extraction & Nesting',
    '[A] 3D Print - Nest Model',
    '[A] AAA - Model Nesting',
    '[A] AAA - Nest Abutment Insertion Guide',
    '[A] AAA - Nest Denture Base',
    '[A] AAA - Nest Denture Teeth',
    '[A] AAA - Nest Night Guard',
    '[A] AAA - Nest Printed Temp Crown',
    '[A] AAA - Nest Soft Tissue',
    '[A] AAA - Nest Surgical Guide',
    '[A] AAA - Nest T-Die',
    '[A] AAA - Nest Try-In',
    '[A] Nest Abutment Insertion Guide',
    '[A] STL Extraction & Nesting',
    '[A] [A] AAA - Model Nesting',
    '[A] [A] AAA - Nest Night Guard',
    '[A] [A] [A] AAA - Model Nesting',
    '[R] 3D Print - Nest Model',
    '[R] AAA - Model Nesting',
    '[R] AAA - Nest Abutment Insertion Guide',
    '[R] AAA - Nest Denture Base',
    '[R] AAA - Nest Denture Teeth',
    '[R] AAA - Nest Night Guard',
    '[R] AAA - Nest Printed Temp Crown',
    '[R] AAA - Nest Soft Tissue',
    '[R] AAA - Nest Surgical Guide',
    '[R] AAA - Nest T-Die',
    '[R] AAA - Nest Try-In',
    '[R] Nest Abutment Insertion Guide',
    '[R] STL Extraction & Nesting',
    '[R] [R] AAA - Model Nesting',
    '[R] [R] AAA - Nest Denture Teeth',
];

export const STAGE__3DPRT_PRNT__LEHI: string[] = [
    '3D Print  - Print Model',
    'AAA - 3D Post Process',
    'AAA - Cure & Sort Surgical Guide',
    'AAA - Match & Sort NG/Try-In',
    'AAA - Match Soft Tissue',
    'AAA - Post Process Abutment Insertion Guide',
    'AAA - Post Process Final Dentures',
    'AAA - Post Process Night Guard',
    'AAA - Post Process Soft Tissue',
    'AAA - Post Process Surgical Guide',
    'AAA - Post Process Try-Ins',
    'AAA - Pull Wash & Dry Denture Base',
    'AAA - Pull Wash & Dry Denture Teeth',
    'AAA - Pull Wash & Dry Surgical Guide',
    'AAA - Sort Denture',
    'AAA - Sort Denture Base',
    'AAA - Sort Denture Teeth',
    'AAA - Sort Temp Crown',
    'AAA - Wash & Cure Abutment Insertion Guide',
    'AAA - Wash & Cure NG/Try-in',
    'AAA - Wash & Cure Soft Tissue',
    'AAA - Wash & Cure Temp Crown',
    'Match Denture',
    'Match Night Guard',
    'Print & Polish Surgical Guide',
    'Print Denture Try Ins',
    'Print Final Denture',
    'Print Night Guard',
    'Print Night Guard & Model',
    'Print Surgical Guide',
    'Print Temp Crown',
    'Pull, Wash & Dry Models',
    'Remove Supports & Wash Denture',
    'Remove Supports & Wash Night Guard',
    'Wash & Cure Abutment Insertion Guide',
    'Wash & Cure Model',
    '[A] 3D Print  - Print Model',
    '[A] AAA - 3D Post Process',
    '[A] AAA - Cure & Sort Surgical Guide',
    '[A] AAA - Match & Sort NG/Try-In',
    '[A] AAA - Match Soft Tissue',
    '[A] AAA - Post Process Abutment Insertion Guide',
    '[A] AAA - Post Process Final Dentures',
    '[A] AAA - Post Process Night Guard',
    '[A] AAA - Post Process Soft Tissue',
    '[A] AAA - Post Process Surgical Guide',
    '[A] AAA - Post Process Try-Ins',
    '[A] AAA - Pull Wash & Dry Denture Base',
    '[A] AAA - Pull Wash & Dry Denture Teeth',
    '[A] AAA - Pull Wash & Dry Surgical Guide',
    '[A] AAA - Sort Denture',
    '[A] AAA - Sort Denture Base',
    '[A] AAA - Sort Denture Teeth',
    '[A] AAA - Sort Temp Crown',
    '[A] AAA - Wash & Cure Abutment Insertion Guide',
    '[A] AAA - Wash & Cure NG/Try-in',
    '[A] Print & Polish Surgical Guide',
    '[A] Print Denture Try Ins',
    '[A] Print Final Denture',
    '[A] Print Night Guard & Model',
    '[A] Print Temp Crown',
    '[A] Pull, Wash & Dry Models',
    '[A] Wash & Cure Abutment Insertion Guide',
    '[R] 3D Print  - Print Model',
    '[R] AAA - 3D Post Process',
    '[R] AAA - Cure & Sort Surgical Guide',
    '[R] AAA - Match & Sort NG/Try-In',
    '[R] AAA - Match Soft Tissue',
    '[R] AAA - Post Process Abutment Insertion Guide',
    '[R] AAA - Post Process Final Dentures',
    '[R] AAA - Post Process Night Guard',
    '[R] AAA - Post Process Soft Tissue',
    '[R] AAA - Post Process Surgical Guide',
    '[R] AAA - Post Process Try-Ins',
    '[R] AAA - Pull Wash & Dry Denture Base',
    '[R] AAA - Pull Wash & Dry Denture Teeth',
    '[R] AAA - Pull Wash & Dry Surgical Guide',
    '[R] AAA - Sort Denture',
    '[R] AAA - Sort Denture Base',
    '[R] AAA - Sort Denture Teeth',
    '[R] AAA - Wash & Cure Abutment Insertion Guide',
    '[R] AAA - Wash & Cure NG/Try-in',
    '[R] AAA - Wash & Cure Soft Tissue',
    '[R] AAA - Wash & Cure Temp Crown',
    '[R] Print & Polish Surgical Guide',
    '[R] Print Denture Try Ins',
    '[R] Print Final Denture',
    '[R] Print Night Guard & Model',
    '[R] Print Temp Crown',
    '[R] Pull, Wash & Dry Models',
    '[R] Wash & Cure Abutment Insertion Guide',
];

export const STAGE__ABUT_MILL__LEHI: string[] = [
    'AAA - Mill Custom Abutment',
    '[A] AAA - Mill Custom Abutment',
    '[R] AAA - Mill Custom Abutment',
];

export const STAGE__CROWN_NEST__LEHI: string[] = [
    'AAA - Emax Nesting',
    'AAA - Nest Custom Abutment',
    'AAA - Nest Hybrid Abutment',
    'AAA - Nest PMMA',
    'AAA - Zirconia Nesting',
    '[A] AAA - Emax Nesting',
    '[A] AAA - Nest Custom Abutment',
    '[A] AAA - Nest Hybrid Abutment',
    '[A] AAA - Nest PMMA',
    '[A] AAA - Zirconia Nesting',
    '[A] [A] AAA - Zirconia Nesting',
    '[R] AAA - Emax Nesting',
    '[R] AAA - Nest Custom Abutment',
    '[R] AAA - Nest Hybrid Abutment',
    '[R] AAA - Nest PMMA',
    '[R] AAA - Zirconia Nesting',
    '[R] [R] AAA - Zirconia Nesting',
];

export const STAGE__DENT_FIN__LEHI: string[] = [
    'AAA - Bond',
    'AAA - Debur Teeth & Base',
    'AAA - Glaze',
    'AAA - Polish & Finish Try In',
    'AAA - Stage 2 Finish',
    'AAA - Stage 3 Finish',
    '[A] AAA - Bond',
    '[A] AAA - Debur Teeth & Base',
    '[A] AAA - Glaze',
    '[A] AAA - Polish & Finish Try In',
    '[A] AAA - Stage 2 Finish',
    '[A] AAA - Stage 3 Finish',
    '[R] AAA - Bond',
    '[R] AAA - Debur Teeth & Base',
    '[R] AAA - Glaze',
    '[R] AAA - Polish & Finish Try In',
    '[R] AAA - Stage 2 Finish',
    '[R] AAA - Stage 3 Finish',
];

export const STAGE__DESGN_CB__LEHI: string[] = [
    'Design - Anterior FC',
    'Design - Anterior Layrd',
    'Design - Posterior FC',
    'Design - Posterior Layrd',
    '[A] Design - Anterior Layrd',
    '[A] Design - Posterior FC',
    '[A] Design - Posterior Layrd',
    '[R] Design - Anterior Layrd',
    '[R] Design - Posterior FC',
    '[R] Design - Posterior Layrd',
];

export const STAGE__EMAX_MILL__LEHI: string[] = ['AAA - Mill Emax', '[A] AAA - Mill Emax', '[R] AAA - Mill Emax'];

export const STAGE__IMP_DEPT__LEHI: string[] = [
    'AAA - Finish Abutment Insertion Guide',
    'AAA - Finish PMMA Anterior',
    'AAA - Finish PMMA Posterior',
    'AAA - Finish Temp Crown',
    'AAA - Implant Crown Seat & Parts Validation',
    'AAA - Implant Final Review & Bond',
    'AAA - Singulate & Finish Custom Abutment',
    '[A] AAA - Finish Abutment Insertion Guide',
    '[A] AAA - Finish PMMA Posterior',
    '[A] AAA - Finish Temp Crown',
    '[A] AAA - Implant Crown Seat & Parts Validation',
    '[A] AAA - Implant Final Review & Bond',
    '[A] AAA - Singulate & Finish Custom Abutment',
    '[R] AAA - Finish Abutment Insertion Guide',
    '[R] AAA - Finish Temp Crown',
    '[R] AAA - Implant Crown Seat & Parts Validation',
    '[R] AAA - Implant Final Review & Bond',
    '[R] AAA - Singulate & Finish Custom Abutment',
];

export const STAGE__IMP_FINISH__LEHI: string[] = [
    'Bond Implant',
    'Final Review/Bond',
    'Finish Abutment Insertion Guide',
    'Gold Anodize & Polish Custom Abutment',
    'Implant Dpt - Crown Seat',
    'Implant Parts Compatibility Validation',
    'Implant Parts Inventory Pull',
    'Implant Parts and Seat  Validation',
    'Match Custom Abutment - Outsourced',
    'Outsource Custom Abutment',
    'QC Implant Parts Fit (Pre-S&G) - Implant Dpt',
    'Singulate & Finish Custom Abutment',
    '[A] Bond Implant',
    '[A] Final Review/Bond',
    '[A] Finish Abutment Insertion Guide',
    '[A] Implant Dpt - Crown Seat',
    '[A] Implant Parts Inventory Pull',
    '[A] Implant Parts and Seat  Validation',
    '[A] Match Custom Abutment - Outsourced',
    '[A] Outsource Custom Abutment',
    '[A] Singulate & Finish Custom Abutment',
    '[R] Final Review/Bond',
    '[R] Finish Abutment Insertion Guide',
    '[R] Implant Dpt - Crown Seat',
    '[R] Implant Parts Inventory Pull',
    '[R] Implant Parts and Seat  Validation',
    '[R] Match Custom Abutment - Outsourced',
    '[R] Outsource Custom Abutment',
    '[R] Singulate & Finish Custom Abutment',
    '[R] [R] Singulate & Finish Custom Abutment',
];

export const STAGE__MILL_MACH__LEHI: string[] = [
    'Mill Authentic Custom Abutment',
    'Mill Generic Custom Abutment',
    'Mill PMMA',
    'Mill Zirconia',
    '[A] Mill Authentic Custom Abutment',
    '[A] Mill Generic Custom Abutment',
    '[A] Mill PMMA',
    '[A] Mill Zirconia',
    '[R] Mill Authentic Custom Abutment',
    '[R] Mill Generic Custom Abutment',
    '[R] Mill PMMA',
    '[R] Mill Zirconia',
];

export const STAGE__MILL_MTECH__LEHI: string[] = [
    'Dandy Emax Mill',
    'Dandy Mill',
    'Mill Generic Custom Abutment',
    '[A] Dandy Emax Mill',
    '[A] Dandy Mill',
    '[R] Dandy Emax Mill',
    '[R] Dandy Mill',
    '[R] [R] Dandy Mill',
];

export const STAGE__MILL_NEST__LEHI: string[] = [
    'Dandy Nesting',
    'Nest Custom Abutment',
    'Nest Emax',
    'Nest Zirconia',
    'Nest e.Max',
    'STL Extraction & File Transfer',
    '[A] Dandy Nesting',
    '[A] Nest Custom Abutment',
    '[A] Nest Emax',
    '[A] Nest Zirconia',
    '[A] STL Extraction & File Transfer',
    '[A] [A] Dandy Nesting',
    '[R] Dandy Nesting',
    '[R] Nest Custom Abutment',
    '[R] Nest Emax',
    '[R] Nest Zirconia',
    '[R] STL Extraction & File Transfer',
    '[R] [R] Dandy Nesting',
];

export const STAGE__NGT_GUARD__LEHI: string[] = [
    'AAA - Polish & Finish NG',
    '[A] AAA - Polish & Finish NG',
    '[R] AAA - Polish & Finish NG',
    '[R] [R] AAA - Polish & Finish NG',
];

export const STAGE__ORDERENTRY__LEHI: string[] = ['STL Extraction & File transfer'];

export const STAGE__ORDERMGMT__LEHI: string[] = [
    'STL Extraction & File Transfer',
    'Scan',
    'Sleeve-Parts  Iventory Pull',
    '[A] STL Extraction & File Transfer',
    '[A] Sleeve-Parts  Iventory Pull',
    '[R] STL Extraction & File Transfer',
];

export const STAGE__PARTS_PULL__LEHI: string[] = [
    'AAA - Outsource Custom Abutment',
    'AAA - Outsource Gold Crown',
    'AAA - Parts Pull',
    '[A] AAA - Outsource Custom Abutment',
    '[A] AAA - Outsource Gold Crown',
    '[A] AAA - Parts Pull',
    '[R] AAA - Outsource Custom Abutment',
    '[R] AAA - Outsource Gold Crown',
    '[R] AAA - Parts Pull',
];

export const STAGE__QC__LEHI: string[] = [
    'AAA - QC Anterior FC',
    'AAA - QC Emax',
    'AAA - QC Final Dentures',
    'AAA - QC Gold Crown',
    'AAA - QC Implants',
    'AAA - QC NG',
    'AAA - QC PFZ',
    'AAA - QC Posterior FC',
    'AAA - QC Surgical Guides',
    'AAA - QC Try In',
    '[A] AAA - QC Anterior FC',
    '[A] AAA - QC Emax',
    '[A] AAA - QC Implants',
    '[A] AAA - QC NG',
    '[A] AAA - QC PFZ',
    '[A] AAA - QC Posterior FC',
    '[R] AAA - QC Anterior FC',
    '[R] AAA - QC Emax',
    '[R] AAA - QC Final Dentures',
    '[R] AAA - QC Implants',
    '[R] AAA - QC NG',
    '[R] AAA - QC PFZ',
    '[R] AAA - QC Posterior FC',
    '[R] AAA - QC Surgical Guides',
    '[R] AAA - QC Try In',
];

export const STAGE__QC_4__LEHI: string[] = ['Final QC - 4', '[A] Final QC - 4', '[R] Final QC - 4'];

export const STAGE__QC_5__LEHI: string[] = ['Final QC - 5', '[A] Final QC - 5', '[R] Final QC - 5'];

export const STAGE__QC_6__LEHI: string[] = ['Final QC - 6', '[A] Final QC - 6', '[R] Final QC - 6'];

export const STAGE__QC_IMP_ANT__LEHI: string[] = [
    'Final QC - Anterior/Layered C&B and Implant',
    '[A] Final QC - Anterior/Layered C&B and Implant',
    '[R] Final QC - Anterior/Layered C&B and Implant',
];

export const STAGE__QC_2__LEHI: string[] = ['Final QC - 2', '[R] Final QC - 2'];

export const STAGE__QC_DENTURE__LEHI: string[] = ['Final QC- Denture', '[A] Final QC- Denture'];

export const STAGE__QC_POSTERI__LEHI: string[] = [
    'Final QC - Posterior Crown & Bridge',
    '[A] Final QC - Posterior Crown & Bridge',
    '[R] Final QC - Posterior Crown & Bridge',
];

export const STAGE__QC_REMOVAB__LEHI: string[] = [
    'Final QC - Removables',
    'Final QC - Removables NG',
    '[A] Final QC - Removables NG',
    '[R] Final QC - Removables NG',
];

export const STAGE__REMOV_MACH__LEHI: string[] = [
    'Cure Denture',
    'Stage 3 Finish',
    '[A] Stage 3 Finish',
    '[R] Cure Denture',
    '[R] Stage 3 Finish',
];

export const STAGE__REMOV_TECH__LEHI: string[] = [
    'Finish & Assemble Denture',
    'Finish & Polish Denture Try Ins',
    'Finish & Polish Night Guard',
    'Finish & Polish Surgical Guide',
    'Finish Printed temp crown',
    'Finish Surgical Guide',
    'QC Parts - Removables Dpt',
    'Sleeve-Parts  Iventory Pull',
    'Stage 2 Finish',
    'Stage 3 Finish',
    '[A] Finish & Assemble Denture',
    '[A] Finish & Polish Denture Try Ins',
    '[A] Finish & Polish Night Guard',
    '[A] Finish & Polish Surgical Guide',
    '[A] Finish Printed temp crown',
    '[A] Stage 2 Finish',
    '[R] Finish & Assemble Denture',
    '[R] Finish & Polish Denture Try Ins',
    '[R] Finish & Polish Night Guard',
    '[R] Finish & Polish Surgical Guide',
    '[R] Stage 2 Finish',
];

export const STAGE__SG_ANTLY__LEHI: string[] = [
    'Implant SG - Anterior Layered - with Model',
    'Pink Porcelain - Add On',
    'Stain & Glaze Anterior Layered',
    'Stain & Glaze Anterior Layered - No Model',
    'Stain & Glaze Anterior Layered - with Model',
    'Stain & Glaze Posterior Layered',
    '[A] Implant SG - Anterior Layered - with Model',
    '[A] Pink Porcelain - Add On',
    '[A] Stain & Glaze Anterior Layered - No Model',
    '[A] Stain & Glaze Anterior Layered - with Model',
    '[R] Implant SG - Anterior Layered - with Model',
    '[R] Pink Porcelain - Add On',
    '[R] Stain & Glaze Anterior Layered',
    '[R] Stain & Glaze Anterior Layered - No Model',
    '[R] Stain & Glaze Anterior Layered - with Model',
    '[R] Stain & Glaze Posterior Layered',
];

export const STAGE__SG_ANTNOLY__LEHI: string[] = [
    'Adjust Fit to Model - Anterior FC',
    'Implant SG - Anterior FC - No Model',
    'Implant SG - Anterior FC - with Model',
    'PMMA Finish - Anterior',
    'Stain & Glaze - Anterior FC - With Model',
    'Stain & Glaze Anterior FC',
    'Stain & Glaze Anterior FC - No Model',
    'Stain & Glaze FC Anterior TLC',
    'Stain & Glaze Posterior FC',
    '[A] Implant SG - Anterior FC - with Model',
    '[A] PMMA Finish - Anterior',
    '[A] Stain & Glaze - Anterior FC - With Model',
    '[A] Stain & Glaze Anterior FC - No Model',
    '[A] Stain & Glaze FC Anterior TLC',
    '[R] Adjust Fit to Model - Anterior FC',
    '[R] Implant SG - Anterior FC - with Model',
    '[R] Stain & Glaze - Anterior FC - With Model',
    '[R] Stain & Glaze Anterior FC',
    '[R] Stain & Glaze Anterior FC - No Model',
    '[R] Stain & Glaze FC Anterior TLC',
    '[R] Stain & Glaze Posterior FC',
];

export const STAGE__SG_FC__LEHI: string[] = [
    'AAA - Add Pink Porcelin',
    'AAA - Adjust Contacts',
    'AAA - Glaze Crown',
    'AAA - SG - Anterior FC EMAX',
    'AAA - SG - Anterior Zirc',
    'AAA - SG - Emax Implant Anterior',
    'AAA - SG - Emax Implant Posterior',
    'AAA - SG - Implant Anterior',
    'AAA - SG - Implant Posterior',
    'AAA - SG - Posterior FC EMAX',
    'AAA - SG - Posterior Model',
    'AAA - SG - Posterior Zirc',
    'AAA - SG Anterior FC',
    'AAA - Stain & Glaze FC TLC',
    'Glaze Crown',
    'Glaze Crown - SPF Test',
    'Inspect Crown - SPF Test',
    'Stain Crown - SPF Test',
    '[A] AAA - Add Pink Porcelin',
    '[A] AAA - Glaze Crown',
    '[A] AAA - SG - Anterior FC EMAX',
    '[A] AAA - SG - Anterior Zirc',
    '[A] AAA - SG - Emax Implant Anterior',
    '[A] AAA - SG - Implant Anterior',
    '[A] AAA - SG - Implant Posterior',
    '[A] AAA - SG - Posterior FC EMAX',
    '[A] AAA - SG - Posterior Zirc',
    '[A] [A] AAA - SG - Anterior Zirc',
    '[R] AAA - Add Pink Porcelin',
    '[R] AAA - Glaze Crown',
    '[R] AAA - SG - Anterior FC EMAX',
    '[R] AAA - SG - Anterior Zirc',
    '[R] AAA - SG - Emax Implant Anterior',
    '[R] AAA - SG - Implant Anterior',
    '[R] AAA - SG - Implant Posterior',
    '[R] AAA - SG - Posterior FC EMAX',
    '[R] AAA - SG - Posterior Zirc',
    '[R] [R] AAA - Add Pink Porcelin',
];

export const STAGE__SG_LAYERED__LEHI: string[] = [
    'AAA - Add Pink Porcelin',
    'AAA - Finish Gold Crown',
    'AAA - SG - Anterior Layered',
    'AAA - SG - Anterior Layered EMAX',
    'AAA - SG - Implant Anterior Layered',
    'AAA - SG - Implant Posterior Layered',
    'AAA - SG - Layered EMAX Implant',
    'AAA - SG - Posterior Layered',
    'AAA - SG - Posterior Layered EMAX',
    '[A] AAA - Add Pink Porcelin',
    '[A] AAA - Finish Gold Crown',
    '[A] AAA - SG - Anterior Layered',
    '[A] AAA - SG - Anterior Layered EMAX',
    '[A] AAA - SG - Implant Anterior Layered',
    '[A] AAA - SG - Implant Posterior Layered',
    '[A] AAA - SG - Layered EMAX Implant',
    '[A] AAA - SG - Posterior Layered',
    '[A] AAA - SG - Posterior Layered EMAX',
    '[R] AAA - Add Pink Porcelin',
    '[R] AAA - Finish Gold Crown',
    '[R] AAA - SG - Anterior Layered',
    '[R] AAA - SG - Anterior Layered EMAX',
    '[R] AAA - SG - Implant Anterior Layered',
    '[R] AAA - SG - Implant Posterior Layered',
    '[R] AAA - SG - Posterior Layered',
    '[R] AAA - SG - Posterior Layered EMAX',
];

export const STAGE__SG_POSLY__LEHI: string[] = [
    'Implant SG - Posterior Layered - No Model',
    'Stain & Glaze Posterior FC',
    'Stain & Glaze Posterior Layered',
    'Stain & Glaze Posterior Layered - No Model',
    'Stain & Glaze Posterior Layered - with Model',
    '[A] Stain & Glaze Posterior Layered - No Model',
    '[A] Stain & Glaze Posterior Layered - with Model',
    '[R] Implant SG - Posterior Layered - No Model',
    '[R] Stain & Glaze Posterior Layered - No Model',
    '[R] Stain & Glaze Posterior Layered - with Model',
];

export const STAGE__SG_POSNOLY__LEHI: string[] = [
    'Adjust Shade - Posterior FC',
    'Dandy Modeless Finish',
    'Implant SG - Posterior FC - with Model',
    'Implant SG - Posterior Layered - With Model',
    'Opaque',
    'PMMA Finish - Posterior',
    'Stain & Glaze  - Posterior FC - With Model',
    'Stain & Glaze FC Posterior TLC',
    'Stain & Glaze Posterior FC',
    'Stain & Glaze Posterior FC - No Model',
    'Stain & Glaze Posterior Full Contour',
    '[A] Implant SG - Posterior FC - with Model',
    '[A] Implant SG - Posterior Layered - With Model',
    '[A] Stain & Glaze  - Posterior FC - With Model',
    '[A] Stain & Glaze FC Posterior TLC',
    '[A] Stain & Glaze Posterior FC - No Model',
    '[R] Dandy Modeless Finish',
    '[R] Implant SG - Posterior FC - with Model',
    '[R] Implant SG - Posterior Layered - With Model',
    '[R] Stain & Glaze  - Posterior FC - With Model',
    '[R] Stain & Glaze FC Posterior TLC',
    '[R] Stain & Glaze Posterior FC',
    '[R] Stain & Glaze Posterior FC - No Model',
    '[R] Stain & Glaze Posterior Full Contour',
];

export const STAGE__SINGULATE__LEHI: string[] = [
    'AAA - Singulate Crown & Bridge',
    'AAA - Singulate Emax',
    'AAA - Singulate Hybrid Abutment',
    'Dandy Emax Sinter',
    'Singulate Crown & Bridge',
    'Singulate Emax',
    'Singulate Hybrid Abutment Coping',
    '[A] AAA - Singulate Crown & Bridge',
    '[A] AAA - Singulate Emax',
    '[A] AAA - Singulate Hybrid Abutment',
    '[A] Dandy Emax Sinter',
    '[A] Singulate Crown & Bridge',
    '[A] Singulate Emax',
    '[A] Singulate Hybrid Abutment Coping',
    '[A] [A] AAA - Singulate Crown & Bridge',
    '[R] AAA - Singulate Crown & Bridge',
    '[R] AAA - Singulate Emax',
    '[R] AAA - Singulate Hybrid Abutment',
    '[R] Dandy Emax Sinter',
    '[R] Singulate Crown & Bridge',
    '[R] Singulate Emax',
    '[R] Singulate Hybrid Abutment Coping',
    '[R] [R] AAA - Singulate Crown & Bridge',
    '[R] [R] AAA - Singulate Emax',
];

export const STAGE__SINT_EMAX__LEHI: string[] = ['AAA - Sinter Emax', '[A] AAA - Sinter Emax', '[R] AAA - Sinter Emax'];

export const STAGE__SINT_MACH__LEHI: string[] = [
    'AAA - Sinter Crown & Bridge',
    'AAA - Sinter Hybrid Abutment',
    'Dandy Emax Sinter',
    'Dandy Sinter',
    'Sinter Hybrid Abutment Coping',
    '[A] AAA - Sinter Crown & Bridge',
    '[A] Dandy Emax Sinter',
    '[A] Dandy Sinter',
    '[A] Sinter Hybrid Abutment Coping',
    '[R] AAA - Sinter Crown & Bridge',
    '[R] AAA - Sinter Hybrid Abutment',
    '[R] Dandy Emax Sinter',
    '[R] Dandy Sinter',
    '[R] Sinter Hybrid Abutment Coping',
    '[R] [R] AAA - Sinter Crown & Bridge',
];

export const STAGE__SINT_TECH__LEHI: string[] = [
    'AAA - Sort Hybrid Abutment',
    'AAA - Sorting - C&B',
    'Dandy Sinter',
    'Match Crown & Bridge',
    'Sort Hybrid Abutment Coping',
    'Sorting',
    'Sorting Emax',
    '[A] AAA - Sort Hybrid Abutment',
    '[A] AAA - Sorting - C&B',
    '[A] Dandy Sinter',
    '[A] Sort Hybrid Abutment Coping',
    '[A] Sorting',
    '[A] Sorting Emax',
    '[R] AAA - Sort Hybrid Abutment',
    '[R] AAA - Sorting - C&B',
    '[R] Dandy Sinter',
    '[R] Match Crown & Bridge',
    '[R] Sort Hybrid Abutment Coping',
    '[R] Sorting',
    '[R] Sorting Emax',
];

export const STAGE__STRAU_MILL__LEHI: string[] = [
    'AAA - Mill Straumann Abutment',
    '[A] AAA - Mill Straumann Abutment',
    '[R] AAA - Mill Straumann Abutment',
];

export const STAGE__SURG_FIN__LEHI: string[] = [
    'AAA - Finish & Polish Surgical Guide',
    '[A] AAA - Finish & Polish Surgical Guide',
    '[R] AAA - Finish & Polish Surgical Guide',
];

export const STAGE__ZIRC_MILL__LEHI: string[] = [
    'AAA - Mill Hybrid Abutment',
    'AAA - Mill PMMA',
    'AAA - Zirconia Milling',
    '[A] AAA - Mill Hybrid Abutment',
    '[A] AAA - Zirconia Milling',
    '[A] [A] AAA - Zirconia Milling',
    '[R] AAA - Mill Hybrid Abutment',
    '[R] AAA - Mill PMMA',
    '[R] AAA - Zirconia Milling',
    '[R] [R] AAA - Zirconia Milling',
];

export const STAGE_LIST_BY_STATION_ID_LEHI: Record<string, string[]> = {
    '3DPRT-MACH': STAGE__3DPRT_MACH__LEHI,
    '3DPRT-MPRI': STAGE__3DPRT_MPRI__LEHI,
    '3DPRT-NEST': STAGE__3DPRT_NEST__LEHI,
    '3DPRT-PRNT': STAGE__3DPRT_PRNT__LEHI,
    'ABUT-MILL': STAGE__ABUT_MILL__LEHI,
    'CROWN-NEST': STAGE__CROWN_NEST__LEHI,
    'DENT-FIN': STAGE__DENT_FIN__LEHI,
    'DESGN-CB': STAGE__DESGN_CB__LEHI,
    'EMAX-MILL': STAGE__EMAX_MILL__LEHI,
    'IMP-DEPT': STAGE__IMP_DEPT__LEHI,
    'IMP-FINISH': STAGE__IMP_FINISH__LEHI,
    'MILL-MACH': STAGE__MILL_MACH__LEHI,
    'MILL-NEST': STAGE__MILL_NEST__LEHI,
    'NGT-GUARD': STAGE__NGT_GUARD__LEHI,
    ORDERENTRY: STAGE__ORDERENTRY__LEHI,
    ORDERMGMT: STAGE__ORDERMGMT__LEHI,
    'PARTS-PULL': STAGE__PARTS_PULL__LEHI,
    QC: STAGE__QC__LEHI,
    'QC - 4': STAGE__QC_4__LEHI,
    'QC - 5': STAGE__QC_5__LEHI,
    'QC - 6': STAGE__QC_6__LEHI,
    'QC IMP-ANT': STAGE__QC_IMP_ANT__LEHI,
    'QC-2': STAGE__QC_2__LEHI,
    'QC-DENTURE': STAGE__QC_DENTURE__LEHI,
    'QC-POSTERI': STAGE__QC_POSTERI__LEHI,
    'QC-REMOVAB': STAGE__QC_REMOVAB__LEHI,
    'REMOV-MACH': STAGE__REMOV_MACH__LEHI,
    'REMOV-TECH': STAGE__REMOV_TECH__LEHI,
    'SG-ANTLY': STAGE__SG_ANTLY__LEHI,
    'SG-ANTNOLY': STAGE__SG_ANTNOLY__LEHI,
    'SG-FC': STAGE__SG_FC__LEHI,
    'SG-LAYERED': STAGE__SG_LAYERED__LEHI,
    'SG-POSLY': STAGE__SG_POSLY__LEHI,
    'SG-POSNOLY': STAGE__SG_POSNOLY__LEHI,
    SINGULATE: STAGE__SINGULATE__LEHI,
    'SINT-EMAX': STAGE__SINT_EMAX__LEHI,
    'SINT-MACH': STAGE__SINT_MACH__LEHI,
    'SINT-TECH': STAGE__SINT_TECH__LEHI,
    'STRAU-MILL': STAGE__STRAU_MILL__LEHI,
    'SURG-FIN': STAGE__SURG_FIN__LEHI,
    'ZIRC-MILL': STAGE__ZIRC_MILL__LEHI,
};
