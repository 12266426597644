import { QRCodeOrderSearch } from '../../../components/QcQrInput/QRCodeOrderSearch';
import { useViewModel } from './BookingForm.vm';
import { ScanResultList } from './components/ScanResultList';
import { SimpleSelect } from '@orthly/ui';
import { Button, Grid, RadioGroup, Text } from '@orthly/ui-primitives';
import label from '@orthly/ui/assets/images/pan-code-label.png';
import React from 'react';

/** Submit a booking action for a case, station, and substation. */
export const BookingForm: React.VFC = () => {
    const {
        bookingAction,
        bookingActionOptions,
        clearScanResults,
        disabled,
        loading,
        setBookingAction,
        setStage,
        setStationId,
        setSubstationId,
        scanResults,
        stage,
        stageOptions,
        stationId,
        stationIdOptions,
        substationIdOptions,
        submit,
        substationId,
    } = useViewModel();

    return (
        <Grid container xs={12}>
            <Grid xs={12}>
                <Text color={bookingAction === '' ? 'ATTENTION' : undefined}>Select book on / book off</Text>
                <RadioGroup
                    disabled={loading}
                    handleChange={setBookingAction}
                    options={bookingActionOptions}
                    value={bookingAction}
                />
            </Grid>
            {bookingAction !== '' && (
                <Grid xs={12}>
                    <Text color={stationId === '' ? 'ATTENTION' : undefined}>Select your department</Text>
                    <SimpleSelect
                        disabled={loading}
                        label={'Department'}
                        onChange={setStationId}
                        options={stationIdOptions}
                        value={stationId}
                    />
                </Grid>
            )}
            {stationId !== '' && (
                <>
                    {substationIdOptions.length > 0 && (
                        <Grid xs={12}>
                            <Text>Select your machine ID</Text>
                            <SimpleSelect
                                disabled={loading}
                                label={'Machine ID'}
                                onChange={setSubstationId}
                                options={substationIdOptions}
                                value={substationId}
                            />
                        </Grid>
                    )}
                    {stageOptions.length > 0 && (
                        <Grid xs={12}>
                            <Text>Select your stage</Text>
                            <SimpleSelect
                                disabled={loading}
                                label={'Stage'}
                                onChange={setStage}
                                options={stageOptions}
                                value={stage}
                            />
                        </Grid>
                    )}
                    <Grid xs={12}>
                        <Text color={'ATTENTION'}>Place your cursor in the input box below and scan the QR code</Text>
                        <img src={label} alt={'Pan Code Label'} style={{ width: '500px' }} />
                        <QRCodeOrderSearch disabled={disabled} label={'Scan QR code'} setActiveSearch={submit} />
                    </Grid>
                </>
            )}
            <Grid container>
                <Grid item xs={4} />
                <Grid item xs={8}>
                    <Button disabled={scanResults.length === 0} onClick={clearScanResults} variant={'text'}>
                        Clear Scan Results
                    </Button>
                </Grid>
            </Grid>
            <Grid xs={12}>
                <ScanResultList scanResults={scanResults} />
            </Grid>
        </Grid>
    );
};
