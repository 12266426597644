import * as ScanResultList from './ScanResultList.vm';
import _ from 'lodash';
import React from 'react';

export type ScanResult =
    | {
          id: string;
          orderNumber: string;
          result: 'SUCCESS';
          stack: string;
      }
    | {
          id: string;
          result: 'ERROR';
      };

export function addScanResult(scanResults: ScanResult[], toAdd: ScanResult): ScanResult[] {
    const combined = scanResults.concat(toAdd);
    return _.uniqBy(combined, 'id');
}

export function clearScanResults(): ScanResult[] {
    return [];
}

export type ReduceScanResultsAction = { type: 'ADD'; value: ScanResult } | { type: 'CLEAR' };

export function reduceScanResults(scanResults: ScanResult[], action: ReduceScanResultsAction): ScanResult[] {
    if (action.type === 'ADD') {
        return ScanResultList.addScanResult(scanResults, action.value);
    }
    if (action.type === 'CLEAR') {
        return ScanResultList.clearScanResults();
    }
    throw new Error('Invalid action for reduceScanResults.');
}

export function useViewModel() {
    const [scanResults, dispatchScanResults] = React.useReducer(reduceScanResults, []);

    const addScanResult = (v: ScanResult) => dispatchScanResults({ type: 'ADD', value: v });
    const clearScanResults = () => dispatchScanResults({ type: 'CLEAR' });

    return { addScanResult, clearScanResults, scanResults };
}
