import { Assert } from '@orthly/runtime-utils';
import { OrthlyEnvironment } from '@orthly/shared-types';
import * as Sentry from '@sentry/react';
import {
    type BrowserOptions,
    type reactRouterV5BrowserTracingIntegration,
    replayCanvasIntegration,
} from '@sentry/react';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

const DANDY_METADATA = (globalThis as any).DANDY_METADATA || {};

export enum OrthlyFrontendApps {
    practice = 'practice',
    admin = 'admin',
    labPortal = 'labPortal',
}

export interface FirebaseConfig {
    apiKey?: string;
    authDomain: string;
    databaseURL: string;
    storageBucket: string;
    messagingSenderId: string;
    projectId: string;
    appId: string;
}

export type SentryRouterHistory = Parameters<typeof reactRouterV5BrowserTracingIntegration>[0];

const sentryOrg = 'orthly-5a';

export class OrthlyBrowserConfig {
    constructor(
        public readonly appName: OrthlyFrontendApps,
        private readonly envOverride?: OrthlyEnvironment,
        private readonly versionOverride?: string,
    ) {}

    get isProduction() {
        return this.env === OrthlyEnvironment.production;
    }

    static get isProduction() {
        return OrthlyBrowserConfig.env === OrthlyEnvironment.production;
    }

    static get isStaging() {
        return OrthlyBrowserConfig.env === OrthlyEnvironment.staging;
    }

    static get isDevelopment() {
        return OrthlyBrowserConfig.env === OrthlyEnvironment.development;
    }

    get isDevelopment() {
        return OrthlyBrowserConfig.isDevelopment;
    }

    get version(): string {
        return this.versionOverride ?? OrthlyBrowserConfig.version;
    }

    static get version(): string {
        return process.env.REACT_APP_VERSION || 'unknown';
    }

    get env() {
        return this.envOverride ?? OrthlyBrowserConfig.env;
    }

    static get env() {
        if (process.env.REACT_APP_STAGING === 'true' && process.env.REACT_APP_USE_STAGING_GATEWAY === 'true') {
            return OrthlyEnvironment.staging;
        }
        if (process.env.REACT_APP_USE_QA === 'true') {
            return OrthlyEnvironment.test;
        }
        if (process.env.REACT_APP_LOCAL_PROD) {
            return OrthlyEnvironment.production;
        }
        switch (process.env.NODE_ENV) {
            case 'production':
                return OrthlyEnvironment.production;
            case 'test':
                return OrthlyEnvironment.test;
            default:
                return OrthlyEnvironment.development;
        }
    }

    static get kustomer() {
        return {
            apiKey: this.isProduction
                ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwNzBlMTJmN2RkZmNiMDA4ZWFjNzFiNSIsInVzZXIiOiI2MDcwZTEyZTJlMDNkYTAwMTM4OGVhOTkiLCJvcmciOiI1ZDVlZDc5MWUzNjFiMTAwMTkxY2I5OGUiLCJvcmdOYW1lIjoib3J0aGx5IiwidXNlclR5cGUiOiJtYWNoaW5lIiwicG9kIjoicHJvZDEiLCJyb2xlcyI6WyJvcmcudHJhY2tpbmciXSwiYXVkIjoidXJuOmNvbnN1bWVyIiwiaXNzIjoidXJuOmFwaSIsInN1YiI6IjYwNzBlMTJlMmUwM2RhMDAxMzg4ZWE5OSJ9.W0yDzNmcO9kQ0Q-83S1uMc9KP4dbbwkcY9u-sKKKPYc'
                : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxZTFhYWM5NjZjMDcwOWNkMDk2NGNlMCIsInVzZXIiOiI2MWUxYWFjOWE5YWQ2OGJiZDZjM2YyMzMiLCJvcmciOiI2MWE3ZmQ1YmI0ZjU4NDJiYWRjMmVmYjAiLCJvcmdOYW1lIjoib3J0aGx5LXNhbmRib3giLCJ1c2VyVHlwZSI6Im1hY2hpbmUiLCJwb2QiOiJwcm9kMSIsInJvbGVzIjpbIm9yZy50cmFja2luZyJdLCJleHAiOjE2NDI3ODQwNzIsImF1ZCI6InVybjpjb25zdW1lciIsImlzcyI6InVybjphcGkiLCJzdWIiOiI2MWUxYWFjOWE5YWQ2OGJiZDZjM2YyMzMifQ.NdtSznfaElx8YZOaDtsL12yj5M3lLDhdksyi8rABc0E',
        };
    }

    static get zendesk() {
        return {
            apiKey: this.isProduction ? '69c2a5b3-6032-4979-bb76-821eb56a0d6b' : '28c1399f-7cd6-4d3c-bfd3-d371e3d7cd28',
            baseUrl: this.isProduction
                ? 'https://meetdandyhelp.zendesk.com'
                : 'https://meetdandyhelp1674054952.zendesk.com',
        };
    }

    static get sunshineConversations() {
        return {
            integrationId: this.isProduction ? '64e3bcaf739cd609fbd31b02' : '64b842e7bd3425b3f6fdf353',
        };
    }

    static get pusherKey(): string {
        switch (OrthlyBrowserConfig.env) {
            case OrthlyEnvironment.production:
            case OrthlyEnvironment.staging:
                return process.env.REACT_APP_PUSHER_KEY ?? '';
            case OrthlyEnvironment.test:
            case OrthlyEnvironment.development:
                return 'a2aac358ec1fac143536';
        }

        Assert.unreachable(OrthlyBrowserConfig.env);
        return '';
    }

    get firebase() {
        const PRODUCTION_CONFIG: FirebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_PROD_KEY,
            authDomain: 'orthly-8118b.firebaseapp.com',
            databaseURL: 'https://orthly-8118b.firebaseio.com',
            storageBucket: 'orthly-8118b.appspot.com',
            messagingSenderId: '987138772519',
            projectId: 'orthly-8118b',
            appId: '1:987138772519:web:0ad1d13ff0eb95de',
        };
        const STAGING_CONFIG: FirebaseConfig = {
            apiKey: 'AIzaSyDAKQXiARBCDVmY74FktKRux_MSbrHYFJQ',
            authDomain: 'orthly-staging-36843.firebaseapp.com',
            databaseURL: 'https://orthly-staging-36843.firebaseio.com',
            projectId: 'orthly-staging-36843',
            storageBucket: 'orthly-staging-36843.appspot.com',
            messagingSenderId: '662625840801',
            appId: '1:662625840801:web:71b2425447776300448ae0',
        };
        const config = this.isProduction ? PRODUCTION_CONFIG : STAGING_CONFIG;

        // VAPID keys are used by the server to authorize itself against the FCM servers
        const productionVapidKey =
            'BEdX1A5xsIzUfAxsFsQRKkFjHvPZqHyiD_7RpPwl5YrYTUoXoeZTG6bhXQrmDXkOuoCRgvXX6OnFZZkSPUsjePY';
        const stagingVapidKey =
            'BCX_cj5IRi591Pul1RiHwzL326O4cq4iHHbwH710FO4-FDN4QN8_pwDMRv15lPiYPV4cFFx3LJCNKQZ48gz2AV8';

        return {
            config,
            vapidKey: this.isProduction ? productionVapidKey : stagingVapidKey,
            projectId: config.projectId,
            storageWebUrl: `https://console.cloud.google.com/storage/browser/${config.storageBucket}/`,
        };
    }

    static get labsServerUrl() {
        switch (this.env) {
            case OrthlyEnvironment.production:
                return 'https://api-labs.dandyserv.net';
            case OrthlyEnvironment.staging:
                return 'https://staging-api-labs.dandyserv.net';
            case OrthlyEnvironment.development:
                return `http://localhost:3002`;
            default:
                return `https://labs-${window.location.host}`;
        }
    }

    static get zipstreamUrl() {
        switch (this.env) {
            case OrthlyEnvironment.production:
                return 'https://api-zipstream.dandyserv.net';
            case OrthlyEnvironment.staging:
                return 'https://staging-api-zipstream.dandyserv.net';
            case OrthlyEnvironment.development:
                return 'http://localhost:3009';
            default:
                return `https://zipstream-${window.location.host}`;
        }
    }

    get logging() {
        const dsns: { [Key in OrthlyFrontendApps]: string } = {
            [OrthlyFrontendApps.practice]: 'https://c6bdc6553893474fb2acf41930f227cc@sentry.io/1489422',
            [OrthlyFrontendApps.admin]: 'https://0a96e20fdc954ea28015c6e5fc83c2bb@sentry.io/1730923',
            [OrthlyFrontendApps.labPortal]:
                'https://eb879054d3993d8a6f1b3be7efc04059@o256070.ingest.sentry.io/4506397242621952',
        };
        const releaseVersion = process.env.REACT_APP_VERSION || process.env.PACKAGE_VERSION || '';
        const RELEASE = `${this.appName}@${releaseVersion}`;
        return {
            RELEASE,
            SENTRY_DSN: dsns[this.appName],
            VERSION: releaseVersion,
        };
    }

    sentryConfig(): BrowserOptions {
        const { SENTRY_DSN, RELEASE } = this.logging;

        const sentryEnabled = !!process.env.REACT_APP_ENABLE_SENTRY;

        return {
            dsn: SENTRY_DSN,
            enabled: sentryEnabled,
            debug: false,
            attachStacktrace: true,
            environment: this.env,
            release: RELEASE,
            normalizeDepth: 4,
            beforeBreadcrumb: excludeGraphQLFetch,
            ignoreErrors: [/OTLPExporterError/],
            denyUrls: [
                // EPDPOR-1165 - SunCo flakiness
                /smooch\.io/i,
            ],
            integrations: [replayCanvasIntegration()],
            replaysSessionSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
            initialScope: {
                tags: {
                    build_version: DANDY_METADATA.VERSION || 'unknown',
                    sha: DANDY_METADATA.SHA || 'unknown',
                    build_timestamp: DANDY_METADATA.TIMESTAMP || 0,
                },
            },
        };
    }

    get launchDarklyId() {
        const testId = '5d2a5e50d635a906a61e081f';
        const prodId = '5d2a5e50d635a906a61e0820';
        return this.isProduction ? prodId : testId;
    }

    static getQaRootDomain() {
        // For QA, we need to remove any prefix if one exists, to get the root domain
        return window.location.host.replace(/^(external|practice|lab)-/, '');
    }

    static get labPortalUrl(): string {
        switch (this.env) {
            case OrthlyEnvironment.development:
                return `${window.location.protocol}//${window.location.hostname}:3031`;
            case OrthlyEnvironment.staging:
                return `https://staging-lab.meetdandy.com`;
            case OrthlyEnvironment.production:
                return `https://lab.meetdandy.com`;
            default:
                return `https://lab-${this.getQaRootDomain()}`;
        }
    }

    static get opsPortalUrl(): string {
        switch (this.env) {
            case OrthlyEnvironment.development:
                return `${window.location.protocol}//${window.location.hostname}:3003`;
            case OrthlyEnvironment.staging:
                return `https://staging-ops.orthly.com`;
            case OrthlyEnvironment.production:
                return `https://ops.orthly.com`;
            default:
                return `https://${this.getQaRootDomain()}`;
        }
    }

    // The auth endpoint (i.e. /login) is currently on the retainer service
    // It does have its own domain in production and staging for the ops portal
    static get opsPortalAuthUrl(): string {
        if (process.env.AUTH_URL) {
            return process.env.AUTH_URL;
        }
        // TODO: [EPDPLT-4289] Remove the switch statement, exclusively use the environment variable
        switch (this.env) {
            case OrthlyEnvironment.development:
                return `http://${window.location.hostname}:3004`;
            case OrthlyEnvironment.staging:
                return `https://staging-auth.orthly.com`;
            case OrthlyEnvironment.production:
                return `https://auth.orthly.com`;
            default:
                return `https://api-retainer-${this.getQaRootDomain()}`;
        }
    }

    static get practicePortalUrl(): string {
        // TODO: [EPDPLT-4289] Remove the switch statement, exclusively use the environment variable
        switch (this.env) {
            case OrthlyEnvironment.development:
                return `${window.location.protocol}//${window.location.hostname}:3001`;
            case OrthlyEnvironment.staging:
                return `https://staging-practice.meetdandy.com`;
            case OrthlyEnvironment.production:
                return `https://practice.meetdandy.com`;
            default:
                return `https://practice-${this.getQaRootDomain()}`;
        }
    }

    // The auth endpoint (i.e. /login) is currently on the retainer service
    // It does have its own domain in production and staging for the practice portal
    static get practicePortalAuthUrl(): string {
        if (process.env.AUTH_URL) {
            return process.env.AUTH_URL;
        }
        // TODO: [EPDPLT-4289] Remove the switch statement, exclusively use the environment variable
        switch (this.env) {
            case OrthlyEnvironment.development:
                return `http://${window.location.hostname}:3004`;
            case OrthlyEnvironment.staging:
                return `https://staging-auth.meetdandy.com`;
            case OrthlyEnvironment.production:
                return `https://auth.meetdandy.com`;
            default:
                return `https://api-retainer-${this.getQaRootDomain()}`;
        }
    }

    static get retainerUrl(): string {
        if (process.env.AUTH_URL) {
            return process.env.AUTH_URL;
        }
        // TODO: [EPDPLT-4289] Remove the switch statement, exclusively use the environment variable
        switch (this.env) {
            case OrthlyEnvironment.development:
                return `http://${window.location.hostname}:3004`;
            case OrthlyEnvironment.staging:
                return `https://staging-api-retainer.dandyserv.net`;
            case OrthlyEnvironment.production:
                return `https://api-retainer.dandyserv.net`;
            default:
                return `https://api-retainer-${this.getQaRootDomain()}`;
        }
    }
}

function getSentryReplayId(): string | undefined {
    const replay = Sentry.getReplay();
    return replay?.getReplayId();
}

export function getSentryReplayUrl(): string | undefined {
    const replayId = getSentryReplayId();
    if (!replayId) {
        return undefined;
    }
    return `https://${sentryOrg}.sentry.io/replays/${replayId}/`;
}
