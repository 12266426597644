import { BookingForm } from '../components/BookingForm/BookingForm';
import { Grid } from '@orthly/ui-primitives';

/** The content for the case scanning screen. */
export const CaseScanningRoot: React.VFC = () => {
    return (
        <Grid container>
            <BookingForm />
        </Grid>
    );
};
